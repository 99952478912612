<template>
  <PageContainer :center-vertically="true">
    <PageTitle :register-screen="true">
      {{ trans('LABEL:PasswordUpdate') }}
    </PageTitle>
    <t-card  variant="auth">
      <ValidationObserver ref="form" v-slot="{ invalid }">
        <form
          class="w-full flex flex-col gap-3"
          ref="forgotPasswordForm"
          @submit.prevent="handleFormSubmit"
        >
          <RegisterCard>
            <template #picture>
              <img v-if="resetLinkSentMessage === null" class="w-full" :src="themeConfig.staticPhotos.loginPasswordReset" />
              <img v-else class="w-full" :src="themeConfig.staticPhotos.loginPasswordReseted" />
            </template>
            <template #content>
              <template v-if="resetLinkSentMessage === null">
                <div class="flex flex-col gap-y-3 my-auto">
                  {{ trans('TEXT:PasswordResetComment') }}
                  <FieldWrapper>
                    <TextField
                      autocomplete
                      name="email"
                      field="email"
                      rules="email|required"
                      v-model="email"
                      :placeholder="trans('LABEL:Email')"
                    />
                  </FieldWrapper>
                </div>
              </template>
              <template v-else>
                {{ resetLinkSentMessage }}
              </template>
            </template>
            <template #bottom-left>
              <Button
                v-if="resetLinkSentMessage === null"
                variant="link"
                :to="{name: 'auth-login'}"
                :text="trans('BUTTON:BackToLogin')"
              />
            </template>
            <template #bottom-right>
              <template v-if="resetLinkSentMessage === null">
                <Button
                  type="submit"
                  :loading="passwordForgotLoading"
                  :disabled="invalid"
                  :text="trans('BUTTON:Send')"
                />
              </template>
              <template v-else>
                <Button
                  variant="link"
                  :to="{name: 'auth-login'}"
                  :text="trans('Powrót do ekranu logowania')"
                />
              </template>
            </template>
          </RegisterCard>
        </form>
      </ValidationObserver>
    </t-card>
  </PageContainer>
</template>

<script>
import {mapState, mapActions, mapGetters, mapMutations} from 'vuex'
import Button from "@/utils/crud/components/Button";
import TextField from "@/utils/crud/components/field-types/Text";
import FieldWrapper from "@/utils/crud/components/field-types/components/FieldWrapper";
import PageContainer from "@/layouts/components/pages/PageContainer";
import PageTitle from "@/layouts/components/pages/PageTitle";
import Hint from "@/views/content/components/Hint"
import CheckboxField from "@/utils/crud/components/field-types/Checkbox.vue";
import RegisterCard from "@/views/content/register/RegisterCard.vue";
import themeConfig from "@/themeConfig";
import api from "@/common/services/api";
import router from "@/router";

export default {
  data () {
    return {
      email: '',
	    passwordForgotLoading: false,
	    resetLinkSentMessage: null,
    }
  },
  components: {
	  RegisterCard, CheckboxField,
    Button, TextField, FieldWrapper, Hint, PageContainer, PageTitle
  },
  computed: {
	  themeConfig() {
		  return themeConfig
	  },
  },
  methods: {
	  goToLogin() {
			router.push({name: 'auth-login'})
	  },
    handleFormSubmit (e) {
      e.preventDefault();

      if (this.email) {
        if(this.$refs.form.validate()) {
	        api.setLocale();
	        api.post('/forgot-password', {email: this.email})
			        .then(
					        success => {
										this.resetLinkSentMessage = success.message;
						        this.passwordForgotLoading = true;
					        },
					        error => {
						        this.$store.dispatch('alert/error', error, { root: true });
					        }
			        ).finally(() => {
				          this.passwordForgotLoading = false;
	        })
        }
      }
    }
  },

}
</script>

<style lang="scss">
</style>
